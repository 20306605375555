import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext(null);

export const useLanguage = () => useContext(LanguageContext);

export default function LanguageContextProvider({ children }) {
  const [language, setLanguage] = useState("en");

  const handleLanguage = (ln) => {
    setLanguage(ln);
  };

  return (
    <LanguageContext.Provider value={{ language, handleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
