import { ThemeProvider } from "@material-tailwind/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-lazy-load-image-component/src/effects/blur.css";
// import "simplebar-react/dist/simplebar.min.css";
import { Suspense } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Loader from "./components/common/Loader";
import LanguageContextProvider from "./contexts/LanguageContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
const App = React.lazy(() => import("./App"));

// <div className="h-screen w-screen justify-center items-center text-center flex text-xl text-white">
//   Loading...
// </div>
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Suspense
        fallback={
          <Loader className="h-screen w-screen justify-center items-center text-center flex text-base text-white" />
        }
      >
        <LanguageContextProvider>
          <App />
        </LanguageContextProvider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
